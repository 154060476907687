<template>
  <!-- 横向排名占比柱状图 -->
  <div class="rank-chart">
    <MyChart :options="options" />
  </div>
</template>

  <script>
import MyChart from "@/components/echart/echart.vue";
import * as echarts from "echarts";
export default {
  props: {
    xData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    seriesData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    changeEchart: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MyChart,
  },
  data() {
    return {
      options: {},
    };
  },
  watch: {
    seriesData: {
      immediate: true,
      handler(val) {
        console.log("val", val);
        if (val && val.length > 0) {
          this.initChart();
        }
      },
    },
    changeEchart: {
      immediate: true,
      handler(val) {
        this.initChart();
      },
    },
  },
  methods: {
    initChart() {
      var xData = this.xData;
      var seriesData = this.seriesData;
      this.options = {
        grid: {
          left: "5%",
          right: "5%",
          bottom: "0%",
          top: "8%",
          containLabel: true,
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666"],
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        xAxis: {
          showBackground: true,
          nameTextStyle: {
            color: "#c0c3cd",
            padding: [0, 0, -10, 0],
            fontSize: 14,
          },
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#555f58",
            },
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: "#333", //坐标轴字颜色
            },
            margin: 15,
          },
          axisTick: {
            show: false, //隐藏X轴刻度
          },
          splitLine: {
            //网格线
            show: false,
          },
          data: xData,
          type: "category",
        },
        yAxis: [
          {
            axisLine: {
              show: true, //隐藏X轴轴线
              lineStyle: {
                color: "rgba(220,220,220,0.3)",
              },
            },
            axisTick: {
              show: false, //隐藏X轴刻度
            },
            axisLabel: {
              textStyle: {
                color: "#333",
              },
            },
            splitLine: {
              //网格线
              show: true,
              lineStyle: {
                color: "rgba(220,220,220,0.3)",
              },
            },
          },
          {
            type: "value", // 右边的y轴
            name: "托管面积（亩）",
            nameTextStyle: {
              fontSize: 14,
              color: "#333",
              padding: [10, -70, 10, 0],
            },
          },
        ],
        // dataZoom: [
        //   {
        //     type: "inside", //图表下方的伸缩条
        //     show: true, //是否显示
        //     realtime: true, //
        //     start: 0, //伸缩条开始位置（1-100），可以随时更改
        //     end: 50, //伸缩条结束位置（1-100），可以随时更改
        //   },
        // ],
        series: (function () {
          let series = [];
          for (let i = 0; i < seriesData.length; i++) {
            let serie = {
              name: seriesData[i].name,
              type: "bar",
              barWidth: "17%",
              data: seriesData[i].value,
              yAxisIndex: i == 0 ? 1 : 0,
              label: {
                show: true,
                position: "insideBottom",
                padding: [5, 0, -7, 150], // 调整文字与文字背景的距离
                rotate: 90,
                formatter: function (params) {
                  return `{a|${params.value}：}{b|${params.seriesName}}`;
                },
                rich: {
                  a: {
                    color: "#333",
                    fontSize: 14,
                    fontWeight: 600,
                  },
                  b: {
                    color: "#333",
                  },
                },
              },
            };
            series.push(serie);
          }
          return series;
        })(),
      };
    },
  },
};
</script>

  <style lang="less" scoped>
.rank-chart {
  width: 100%;
  height: 100%;
}
</style>
